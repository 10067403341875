<template>
  <div>
    <v-data-table
      v-bind="{ ...attr }"
      :headers="headers"
      :items="data"
      v-model="selected"
      :loading="searching"
      :showSelect="showSelect"
      :items-per-page="options.itemsPerPage"
      :server-items-length="total && !disableServerItemsLength ? total : -1"
      :hide-default-footer="
        (!hideFooter && data && data.length) > 0 ? false : true
      "
      overflowX
      :sort-by="defaultSortBy"
      calculate-widths
      :options.sync="options"
      :disable-sort="disableSort"
      class="elevation-1 table-custom"
      :multi-sort="multiSort"
      :disable-pagination="disablePagination"
      :footer-props="{
        itemsPerPageText:
          `${total && !disableServerItemsLength ? total : data.length}` +
          $t('commons.cases') +
          itemStart +
          '~' +
          itemStop +
          $t('commons.showing'),
        itemsPerPageOptions: itemsPerPageOptions,
        pageText: '',
      }"
    >
      <template slot="no-data"> データはありません </template>
      <template slot="top">
        <slot name="top"></slot>
      </template>
      <template v-for="header in headers" #[`item.${header.value}`]="{ item }">
        <slot :name="[`item.${header.value}`]" :item="item">
          <span :key="header.value" :title="getData(item, header.value)">{{ getData(item, header.value) }}</span>
        </slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: false,
      searching: false,
      selected: [],
      options: {
        page: 1,
        itemsPerPage: this.itemsPerPage,
      },
      timeoutSearch: null,
      orderBy: [],
    };
  },
  computed: {
    data: {
      get() {
        if (this.items) {
          return this.items;
        }
        return [];
      },
    },
    itemStart() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1;
    },
    itemStop() {
      return Math.min(
        this.total && !this.disableServerItemsLength
          ? this.total
          : this.data.length,
        this.options.page * this.options.itemsPerPage,
      );
    },
    headerByValue () {
      return this.headers.reduce((result, header) => {
        result[header.value] = header
        return result
      }, {})
    }
  },
  props: {
    showSelect: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    attr: Object,
    itemsPerPageOptions: Array,
    headers: Array,
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      default: 5,
      type: Number,
    },
    filter: {
      default: () => {},
      type: Object,
    },
    total: Number,
    disablePagination: {
      type: Boolean,
      default: false,
    },
    funReset: {
      type: Function,
      default: null,
    },
    multiSort: {
      type: Boolean,
      default: false,
    },
    sortField: {
      type: Array,
      default: () => [],
    },
    disableServerItemsLength: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: String,
  },
  watch: {
    options: {
      handler() {
        this.fetchListTmp();
      },
      deep: true,
    },
    selected() {
      this.$emit('setSelected', this.selected);
    },
  },
  methods: {
    getData (item, key) {
      if (!key || !item) return ''
      const prefixes = key.split('.');
      return prefixes.reduce((o, i) => o[i] ?? '', item);
    },
    fetchListTmp() {
      const arrOrder = [];
      if (this.options.sortBy.length > 0) {
        this.options.sortBy.map((orderItem, ind) => {
          this.options.sortDesc.map((directionItem, index) => {
            if (ind === index) {
              const headerItem = this.headerByValue[orderItem];
              const reversed = !!headerItem.reversed
              const orderItemTmp = {
                fieldName: orderItem,
                direction: directionItem === !reversed ? 'DESC' : 'ASC',
              };
              // check the header has fieldName defined
              const fieldName = headerItem?.fieldName
              if (fieldName) {
                orderItemTmp.fieldName = fieldName
              } else {
                const nameOverride = this.sortField.find(field => field.colName === orderItem)
                if (nameOverride) {
                  const itemFieldName = nameOverride.fieldName
                    ? nameOverride.fieldName + '.'
                    : '';
                  orderItemTmp.fieldName = `${itemFieldName}${nameOverride.name}`
                }
              }
              arrOrder.push(orderItemTmp);
            }
          });
        });
      }
      this.orderBy = arrOrder;
      this.reset();
    },

    // use in search and filter
    async resetPageAndSearch() {
      if (this.options.page === 1) {
        // page is already the 1st, so just search again
        return this.reset();
      } else {
        // must reset the page and search.
        this.options.page = 1;
        // no need to invoke this.search() because the options watcher will do that
      }
    },

    // use in pagination
    reset() {
      this.searching = true;
      this.$store.commit('setLoadingOverlayShow', { root: true });
      if (this.funReset) {
        const variables = {
          orderBy: this.orderBy,
          pagination: {
            take: this.options.itemsPerPage,
            skip: this.itemStart - 1,
          },
          filter: this.filter,
        };
        this.funReset(variables)
          .then(() => {
            this.$store.commit('setLoadingOverlayHide', { root: true });
            this.searching = false;
          })
          .catch(error => {
            console.log(error);
            this.$store.commit('setLoadingOverlayHide', { root: true });
            this.searching = false;
          });
      } else {
        setTimeout(() => {
          this.$store.commit('setLoadingOverlayHide', { root: true });
          this.searching = false;
        }, 700);
      }
    },

    unselect () {
      this.selected = []
    }
  },
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep {
  th,
  .v-data-footer,
  .v-select__selection {
    font-size: 14px !important;
  }

  td {
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;

    // with this the ellipsis-ed text will be visible, but for now not doing it because we are adding title attribute to show the full text in popup
    // &:hover {
    //   overflow: visible; 
    //   white-space: normal;
    //   height:auto;  /* just added this line */
    // }
  }
}
.btn-custom-text {
  margin: 0;
  color: #13ace0;
  font-size: 12px;
}
.pencil {
  background: var(--ffffff) 0% 0% no-repeat padding-box !important;
  background: #13ace0 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1;
  height: 30px;
  width: 30px;
}
.contact {
  color: #13ace0 !important;
}
.icon-btn {
  border-radius: 50%;
  font-size: 23px !important;
  color: #fff !important;
}
.v-data-table::v-deep {
  tr {
    height: auto !important;
  }
  th {
    color: #757575 !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  td {
    color: #757575;
    font-size: 14px !important;
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
      margin: 2px;
    }
  }
  .v-data-footer {
    position: relative;
    font-size: 11px;

    & > div:nth-child(2) {
      margin: 0;
    }
  }
}
::v-deep {
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
</style>
