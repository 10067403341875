<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <h1 class="mb-3">
          {{
            $t(
              'webPageManage.ConnectExistingCustomer.ConnectWithExistingCustomers',
            )
          }}
        </h1>
        <div>
          <h6>{{ $t('webPageManage.ConnectExistingCustomer.CustomerID') }}</h6>
          <v-text-field
            type="number"
            style="width:300px"
            v-model="clientID"
            :rules="rules.clientID"
            class=" mb-5"
            autofocus
          ></v-text-field>
        </div>
        <div class="d-flex flex-row-reverse">
          <div class="d-flex">
            <v-btn large class="t-btn--red-dark" @click="closeDialog">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t('webPageManage.ConnectExistingCustomer.close') }}
            </v-btn>
            <v-btn large class="t-btn--prm ml-3" @click="save">
              {{ $t('webPageManage.ConnectExistingCustomer.save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'

export default {
  name: 'ConnectExistingCustomer',
  data() {
    return {
      rules: {
        clientID: [
          v => !!v || this.$t('rules.requiredField'),
          v => v > 0 || 'ID > 0',
        ],
      },
      clientID: null,
    };
  },
  props: {
    visibleCompChirld: Boolean,
    id: Number,
    type: Number
  },
  computed: {
    dialog: {
      get() {
        return this.visibleCompChirld;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'getWebRequestListVisitreservation',
      'getWebrequestlistExperience'
    ]),
    ...mapMutations(['setCopyErrorText', 'setAlertSuccess','setAlertError', 'setPermissionUser', 'setRoleAdminUser']),

    async save() {
      if (this.$refs.form.validate()) {
        const query = `
            mutation (
              $webRequestId: Int! 
              $clientId: Int! 
            ) {
              assignClientWebRequest (
                webRequestId: $webRequestId
                clientId: $clientId
              ) {
                __typename
              }
            }
          `
        const variables = {
            webRequestId: parseInt(this.id),
            clientId: parseInt(this.clientID)
          }
        await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          if(this.type === 0){
            this.$emit('reset')
            this.$store.dispatch('getWebRequestListVisitreservation')
          }
          if(this.type === 1){
            this.$store.dispatch('getWebrequestlistExperience')
          }
          this.$refs.form.reset()
          this.setAlertSuccess('更新しました。');
          this.closeDialog();
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
      }
      // this.$emit('close');
    },
    
    closeDialog() {
      this.$refs.form.reset()
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h1{
  font-size: 28px;
  color: #212121 ;
  font-weight: bold;
}
h6{
  font-size: 10px;
  font-weight: 600;
  color: #000;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
::v-deep {
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .v-dialog {
    width: 500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .v-btn:not(.v-btn--round).v-size--large{
    height: 36px !important;
  }
}
</style>
